import Typography from '../../atoms/typography'
import { styled } from '@mui/system'
import { Grid as MuiGrid } from '@mui/material'
import Button from '../../molecules/button'

const Root = styled('div')`
	display: flex;
	flex-direction: column;
	position: relative;
	&.shady:before {
		background: transparent linear-gradient(180deg, #00000000 0%, #00000099 100%) 0% 0% no-repeat padding-box;
		bottom: 0;
		content: '';
		height: 250px;
		left: 0;
		position: absolute;
		width: 100%;
		z-index: 1;
	}
`

const Title = styled(Typography)`
	line-height: 1;
	margin-top: 20px;
	margin-bottom: 8px;
	${({ theme }) => theme.breakpoints.up('md')} {
		margin-top: 25px;
	}
	align-items: center;
	color: ${({ theme }) => theme.palette.common.white};
	display: flex;
	font-size: ${({ theme }) => theme.typography.pxToRem(30)};
	letter-spacing: -0.6px;
	.title-info {
		background-color: var(--color-pink);
		display: inline-block;
		font-family: ${({ theme }) => theme.typography.fontFamily};
		font-size: ${({ theme }) => theme.typography.pxToRem(14)};
		font-stretch: normal;
		font-weight: ${({ theme }) => theme.typography.fontWeight};
		letter-spacing: 0;
		margin-left: ${({ theme }) => theme.spacing(2.5)};
		padding: ${({ theme }) => theme.spacing('3px', 0.5)};
	}
`

const ImageColumn = styled('div')``

const TextColumn = styled('div')`
	display: flex;
	justify-content: space-between;
	align-items: center;
	bottom: ${({ theme }) => theme.spacing(3.75)};
	display: flex;
	flex-direction: column;
	position: absolute;
	width: 100%;
	z-index: 1;
`

const CtaContainer = styled(MuiGrid)`
	gap: 22px;
	margin-top: ${({ theme }) => theme.spacing(2.75)};
	align-items: center;
	${({ theme }) => theme.breakpoints.up('md')} {
		gap: ${({ theme }) => theme.spacing(2.25)};
		margin-top: 25px;
		margin-bottom: 0;
	}
	flex-direction: column;
`

const ButtonStyled = styled(Button)`
	color: ${({ theme }) => theme.palette.common.white};
	font-size: ${({ theme }) => theme.typography.pxToRem(16)};
	letter-spacing: 0;
	&:hover {
		color: ${({ theme }) => theme.palette.common.white};
	}
`

export { Title, Root, ImageColumn, TextColumn, CtaContainer, ButtonStyled }
